import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ArticleCard from '../components/ArticleCard'

const Articles = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const articles = edges.map((edge) => (
    <ArticleCard key={edge.node.id} article={edge.node.frontmatter} />
  ))
  return (
    <Layout>
      <Seo title="Articles" description="View all my articles here" />
      <h2 className="font-bold text-2xl text-center my-10">Articles</h2>
      <div className="container mb-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">{articles}</div>
      </div>
    </Layout>
  )
}

export default Articles

export const query = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { path: { regex: "/articles/" }, status: { eq: "live" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            image {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED)
              }
            }
            title
            subtitle
            date(formatString: "MMMM YYYY")
          }
        }
      }
    }
  }
`
